const importAll = (r) => r.keys().map(r)

importAll(require.context('../../images/mutualfunds/', true, /\.(ico|png|jpe?g|svg)$/));

import EventManager from '../../classes/EventManager';
window.EventManager = EventManager

// Base styles
import '../../bootstrap'
import '../../styles'
import '../../stylesheets/mutualfunds/home.styles'

// dynamic loader
import(
  /* webpackChunkName: "mf-application-pack-loader" */
  `../../packLoader/mutualfunds/application`
)
import(
  /* webpackChunkName: "stimulus-controllers" */
  'controllers'
)
